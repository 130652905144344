body {
  margin: 0;
}

//Layout
//Layout
//Layout
//Layout
.layout-app {
  height: 100vh;
  display: block;
  flex-direction: column;
  &.sidebar-open {
    // background: black;
  }
}

.layout-top {
  flex: 0;
  border-bottom: solid 1px var(--adm-color-border);
  display: block;
  width: 100%;
}

.layout-body {
  // flex: 1;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  // flex: 1;
  display: block;
  /* justify-content: center; */
  /* align-items: center; */
  max-height: calc(100% - 100px);
  overflow: scroll;
  margin: 0;
  padding: 0;
}

.layout-bottom {
  // flex: 0;
  position: fixed;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: solid 1px var(--adm-color-border);

  background: #fff;
  z-index: 9990;
  box-shadow: 0px -1px 5px 1px #dfdfdf;
}

//Sidebar
//Sidebar
//Sidebar
//Sidebar
//Sidebar
.sidebar-left {
  position: fixed;
  left: 0;
  top: 0;
  width: 70%;
  height: calc(100vh);
  z-index: 9991;
  background-color: #fff;
  .adm-side-bar {
    width: 100%;
    border-right: 1px solid #f1f1f1;
    background-color: #fff;
    box-shadow: 1px 1px 50px 5px #bdbdbd;
    .adm-side-bar-item {
      background: transparent;
      border-bottom: 1px solid #f1f1f1;
      font-size: 16px;
    }
    .adm-side-bar-item-active {
      // color: rgb(0, 68, 255);
      background: #def5ff;
      // color: aquamarine !important;
      .adm-side-bar-item-highlight {
        span {
          // color: aquamarine !important;
        }
      }
    }
  }
}

//LOGIN page
//LOGIN page
//LOGIN page
//LOGIN page
.login-avatar {
  align-items: center;
  display: inline-block;
  margin: 0 auto;
  width: 100px;
  height: auto;
  margin-top: 15px;
  border-radius: 5px;
}

//Home page
//Home page
//Home page
.home-page {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  width: calc(100% - 20px);
  background: #f9f9f9;
  .adm-card {
    border: 1px solid #f1f1f1;
  }
}

//Home page
//Home page
//Home page
.todo-page {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  width: calc(100% - 20px);
  background: #f9f9f9;
}

//LOADING
//LOADING
//LOADING
//LOADING
.wrapper-loading-overlay {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 1000;
  background-color: rgb(0 0 0 / 70%);
  align-content: center;
  vertical-align: middle;
  margin: 0 auto;
  .adm-spin-loading {
    display: table;
    margin: 0 auto;
    .adm-spin-loading-svg > .adm-spin-loading-fill {
      stroke: #fff;
    }
  }
}
